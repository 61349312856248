import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Breadcumb from '../Breadcumb/Main'
import { Parallax } from 'react-parallax'
import axios from 'axios'
import { Server } from '../../Server'
import { Helmet } from 'react-helmet'
import Consultation from '../Consultation/Main'
import { toast } from 'react-toastify'
import Spinner from '../Spinner/Main'

const About = ({ setErrorvisible }) => {

    const [aboutpageDetail, setaboutPageDetail] = useState([]);
    const [breadCrumbData, setbreadCrumbData] = useState();
    const [pageMetas, setpageMetas] = useState();
    const [spinner, setSpinner] = useState(true);

    const getAboutaboutPageDetail = () => {
        axios
            .get('/api/about-page-details')
            .then((res) => {
                setbreadCrumbData(res.data.breadcrumb_banner)
                setaboutPageDetail(res.data.about_us_details)
                setpageMetas(res.data.page_metas)
                setSpinner(false)
            })
            .catch((error) => {
                setSpinner(false)
                toast.error('Some error occurred!')
                setErrorvisible(true)
            })
    }
    useEffect(() => {
        setErrorvisible(false)
        getAboutaboutPageDetail()
    }, [])
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageMetas && pageMetas.meta_title ? pageMetas.meta_title : 'SwissBrainTec'}</title>
                <meta name='description' content={pageMetas && pageMetas.meta_description} />
                <meta name='keywords' content={pageMetas && pageMetas.meta_keywords} />
            </Helmet>
            {spinner && <Spinner />}
            {aboutpageDetail &&
                <div className='wow fadeIn animated'>
                    <>
                        {breadCrumbData &&
                            <Breadcumb BreadCumbData={breadCrumbData && breadCrumbData} Title={aboutpageDetail && aboutpageDetail.title} Breadcumb={aboutpageDetail && aboutpageDetail.title} />
                        }
                        {aboutpageDetail.pagedetail && aboutpageDetail.pagedetail[1].type === 'about_section_1' &&
                            <section className="about-four section-padding--top wow fadeIn animated mb-4">
                                <div className="container">
                                    <div className="row gutter-y-60">
                                        <div className="col-lg-6 text-center">
                                            <div className="about-four__image">
                                                <img src={`${Server}` + aboutpageDetail.pagedetail[1].image} className="wow fadeInUp"
                                                data-wow-duration="1500ms" alt="" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="about-four__content">
                                                <div className="section-title ">
                                                    <p className="section-title__text">{aboutpageDetail.pagedetail[1].content}</p>
                                                    <h6 className="section-title__title" style={{ fontSize: '34px' }}>{aboutpageDetail.pagedetail[1].main_heading}</h6>
                                                </div>
                                                <div className="about-four__text">
                                                    {aboutpageDetail.pagedetail[1].content_extra}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        }
                        {aboutpageDetail.pagedetail && aboutpageDetail.pagedetail[0].type === 'about_section_2' &&
                            <section className="cta-two mt-5" >
                                <Parallax bgImage={`${Server}` + aboutpageDetail.pagedetail[0].image} bgImageAlt="about" className=" section-padding-lg--top section-padding-lg--bottom" strength={370}>
                                    <div className="container">
                                        <div className="cta-two__inner">
                                            <h3 className="cta-two__title">{aboutpageDetail.pagedetail[0].main_heading}</h3>
                                            <Link to="#" data-bs-toggle="modal" data-bs-target="#exampleModal" className="thm-btn thm-btn--light cta-one__btn mb-3" ><span>Get a quote</span></Link>
                                        </div>
                                    </div>
                                </Parallax>
                            </section>
                        }
                    </>
                </div>
            }
        </>
    )
}

export default About
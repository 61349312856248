import * as Yup from 'yup'

export const inquirySchema = Yup.object({
  email: Yup.string()
    .email('Please enter valid email address!')
    .required('Please enter your email!'),
    customer_name: Yup.string('Name must be a string!')
    .required('Please enter your name!'),
    customer_mobile: Yup.string()
    .required('Please enter your phone number!'),
    email_subject: Yup.string()
    .required('Please enter the subject!'),
    email_message: Yup.string()
    .required('Please enter your query!'),
})

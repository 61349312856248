import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BG from '../../assets/images/background/contact-one-bg-1-1.png'
import Breadcumb from '../Breadcumb/Main'
import { useFormik } from 'formik'
import { inquirySchema } from '../../Schemas/index'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useWebSettingContext } from '../../context/WebSetting';


const Page404 = () => {

    return (
        <>
            <Breadcumb Title='Page-404' Breadcumb='Page-404' />
            <section className="contact-one section-padding--top section-padding--bottom mt-0 pt-4 mb-5">
                <div className="container">
                    <div className='row'>
                            <div class="d-flex align-items-center justify-content-center">
                                <div class="text-center">
                                    <h1 class="display-1 fw-bold section-title__title">404</h1>
                                    <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
                                    <p class="lead">
                                        The page you’re looking for doesn’t exist.
                                    </p>
                                    <Link to={'/'} className="thm-btn contact-one__btn" ><span>Go Home</span></Link>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Page404
import React from 'react'
import { Link } from 'react-router-dom'
import {Server} from '../../Server'

const Breadcumb = ({ BreadCumbData, Title, Breadcumb }) => {
    let breadcrubimage = BreadCumbData && Server+''+BreadCumbData.image;
    return (
        <div className="page-header">
            <div className="page-header__bg"
                style={{ backgroundImage: `url(${breadcrubimage})` }}></div>
            <div className="container">
                <ul className="thm-breadcrumb list-unstyled">
                    <li><Link to="/">Home</Link></li>
                    <li>{Breadcumb && Breadcumb}</li>
                </ul>
                <h2 className="page-header__title">{Title && Title}</h2>
            </div>
        </div>
    )
}

export default Breadcumb
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel'
import BGImg3 from '../../assets/images/shapes/service-four-bg-1-1.png'
import BGImg6 from '../../assets/images/shapes/team-home-bg-1-1.jpg'
import AnimatedNumber from "animated-number-react"
import { Parallax } from 'react-parallax'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import { Server } from '../../Server'
import Star from './Star'
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import { toast } from 'react-toastify'
import Spinner from '../Spinner/Main'
const Home = ({ setErrorvisible }) => {
    const [ytShow, setytShow] = useState(false)
    const [servicesSection, setServicesSection] = useState('');
    const [servicesslider, setservicesslider] = useState([]);
    const [homeslider, sethomeslider] = useState([]);
    const [pageMetas, setpageMetas] = useState('');
    const [aboutCompanyLeft, setaboutCompanyLeft] = useState('');
    const [aboutCompanyRight, setaboutCompanyRight] = useState('');
    const [funFactSection, setFunFactSection] = useState('')
    const [parallaxSection, setparallaxSection] = useState('')
    const [productsSection, setproductsSection] = useState('');
    const [mainproducts, setmainproducts] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [spinner, setSpinner] = useState(true);
    var base_url = window.location.origin;

    const options = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            576: {
                margin: 30,
                items: 2
            },
            768: {
                margin: 30,
                items: 3
            },
            992: {
                margin: 30,
                items: 4
            },
            1200: {
                margin: 30,
                items: 5
            }
        },
    }
    const sliderOptions = {
        loop: true,
        items: 1,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        margin: 0,
        dots: true,
        nav: true,
        animateOut: "slideOutDown",
        animateIn: "fadeIn",
        smartSpeed: 1000,
        autoplay: true,
        autoplayTimeout: 7000,
        autoplayHoverPause: false
    }
    const logoOptions = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        nav: true,
        navText: ["<span class=\"fa-solid fa-angle-left\"></span>", "<span class=\"fa-solid fa-angle-right\"></span>"],
        dots: false,
        margin: 30,
        items: 1,
        smartSpeed: 700,
        responsive: {
            0: {
                margin: 30,
                items: 1
            },
            375: {
                margin: 30,
                items: 1
            },
            575: {
                margin: 30,
                items: 1
            },
            767: {
                margin: 50,
                items: 1
            },
            991: {
                margin: 40,
                items: 1
            },
            1199: {
                margin: 80,
                items: 1
            }
        }
    }
    const getHomeDetails = async () => {
        await axios
            .get('/api/home-page-details')
            .then((res) => {
                sethomeslider(res.data.home_slider)
                setpageMetas(res.data.page_metas)
                setServicesSection(res.data.services_section)
                setservicesslider(res.data.services_section_slider)
                setaboutCompanyLeft(res.data.about_company_left)
                setaboutCompanyRight(res.data.about_company_right)
                setFunFactSection(res.data.fun_fact_section)
                setparallaxSection(res.data.parallax_section)
                setproductsSection(res.data.home_products_section)
                setmainproducts(res.data.main_products)
                setReviews(res.data.reviews_list)
                setSpinner(false)
            })
            .catch((error) => {
                setSpinner(false)
                toast.error('Some error occurred!')
                setErrorvisible(true)
            })
    }

    useEffect(() => {
        setErrorvisible(false)
        getHomeDetails()
    }, [])

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageMetas && pageMetas.meta_title ? pageMetas.meta_title : 'Swiss BrainTec'}</title>
            </Helmet>
            {spinner && <Spinner />}
            {homeslider &&
                <>
                    <div className="slider-one wow fadeIn animated">
                        {
                            homeslider && homeslider.length > 0 &&
                            <>
                                <OwlCarousel className="slider-one__carousel owl-theme thm-owl__carousel" {...sliderOptions}>
                                    {homeslider.map((item, index) => (
                                        <div key={index} className="item slider-one__slide-1">
                                            <div className="slider-one__bg" style={{ backgroundImage: `url(${Server + '' + item.image})` }}>
                                            </div>
                                            <div className="slider-one__line"></div>
                                            <div className="slider-one__shape-1"></div>
                                            <div className="slider-one__shape-2"></div>
                                            <div className="slider-one__shape-3"></div>
                                            <div className="container slider-container">
                                                <div className="slider-one__content ">
                                                    <p className="slider-one__text">{item.sub_heading}</p>
                                                    <div className="slider-one__title-wrapper">
                                                        <h4 className="slider-one__title">{item.main_heading}</h4>
                                                    </div>
                                                    <div className="slider-one__btns">
                                                        <Link to={item.button_link} className="thm-btn slider-one__btn"><span>{item.button_text}</span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </>
                        }

                    </div>

                    {aboutCompanyLeft &&
                        <section className="section-padding--bottom section-padding--top about-five">
                            <div className="container">
                                <div className="row gutter-y-60">
                                    <div className="col-lg-6">
                                        <div className="about-five__images">
                                            <div className="about-five__images__shape"></div>
                                            {aboutCompanyLeft && aboutCompanyLeft.image &&
                                                <img src={`${Server}` + aboutCompanyLeft.image} className="wow fadeInUp"
                                                    data-wow-duration="1500ms" alt="" />
                                            }
                                            {aboutCompanyLeft && aboutCompanyLeft.image &&
                                                <img src={`${Server}` + aboutCompanyLeft.content} alt="" style={{ border: '2px solid #000' }} />
                                            }

                                            <div className="about-five__video">
                                                <Link to="#" onClick={() => setytShow(true)} className="video-popup">
                                                    <i className="fa fa-play"></i>
                                                </Link>
                                            </div>
                                            <div className="about-five__images__caption count-box wow fadeInUp" data-wow-duration="1500ms">
                                                <span className="count-text"><AnimatedNumber value={new Date().getFullYear() - aboutCompanyLeft.col ? new Date().getFullYear() - aboutCompanyLeft.col : 10} duration={2500} formatValue={(v) => Math.round(v)} /></span>
                                                Years <br />
                                                Experience
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="about-five__content">
                                            <div className="section-title ">
                                                <p className="section-title__text">{aboutCompanyRight.content}</p>
                                                <h2 className="section-title__title">{aboutCompanyRight.main_heading}</h2>

                                            </div>
                                            <div className="about-five__text">
                                                <div dangerouslySetInnerHTML={{ __html: aboutCompanyRight.content_extra }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }


                    <section
                        className=" section-padding--top service-four gray-bg section-padding-xl--bottom section-has-bottom-margin background-repeat-no background-position-top-right"
                        style={{ backgroundImage: `url(${BGImg3})` }}>
                        <div className="container">

                            <div className="section-title text-center">
                                <p className="section-title__text">{servicesSection && servicesSection.content}</p>
                                <h2 className="about-five__text" style={{ fontSize: '17px', width: '75%', margin: 'auto' }}>{servicesSection && servicesSection.main_heading}</h2>
                            </div>
                            {servicesslider.length > 0 &&
                                <OwlCarousel
                                    className="thm-owl__carousel thm-owl__carousel--with-shadow service-four__carousel"
                                    {...options}
                                >
                                    {servicesslider.map((item2, index2) => (
                                        <div key={index2} className="item">
                                            <div className="service-card-three">
                                                <div className="service-card-three__icon">
                                                    <div dangerouslySetInnerHTML={{ __html: item2.col }} />
                                                </div>
                                                <div className="service-card-three__content">
                                                    <h3 className="service-card-three__title"><Link to={item2.content_extra}>{item2.main_heading}</Link></h3>
                                                    <div className="service-card-three__text">{item2.content}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            }
                        </div>
                    </section>

                    <section className="funfact-one">
                        <div className="container">
                            <div className="funfact-one__inner wow fadeInUp background-size-cover" data-wow-duration="1500ms">
                                <ul className="funfact-one__list">
                                    <li className="funfact-one__list__item">
                                        <h3 className="funfact-one__list__title count-box text-center">
                                            <span data-stop="255" data-speed="2500" className="count-text"><AnimatedNumber value={new Date().getFullYear() - funFactSection.main_heading ? new Date().getFullYear() - funFactSection.main_heading : 10} duration={2500} formatValue={(v) => Math.round(v)} /></span>
                                        </h3>
                                        <p className="funfact-one__list__text">Years of Experience</p>
                                    </li>
                                    <li className="funfact-one__list__item">
                                        <h3 className="funfact-one__list__title count-box text-center">
                                            <span data-stop="325" data-speed="2500" className="count-text"><AnimatedNumber value={funFactSection && funFactSection.content} duration={2500} formatValue={(v) => Math.round(v)} /></span>
                                        </h3>
                                        <p className="funfact-one__list__text">Projects Completed</p>
                                    </li>
                                    <li className="funfact-one__list__item">
                                        <h3 className="funfact-one__list__title count-box text-center">
                                            <span data-stop="569" data-speed="2500" className="count-text"><AnimatedNumber value={funFactSection && funFactSection.col} duration={2500} formatValue={(v) => Math.round(v)} /></span>
                                        </h3>
                                        <p className="funfact-one__list__text">Professional Experts</p>
                                    </li>
                                    <li className="funfact-one__list__item">
                                        <h3 className="funfact-one__list__title count-box text-center">
                                            <span data-stop="769" data-speed="2500" className="count-text"><AnimatedNumber value={funFactSection && funFactSection.content_extra} duration={2500} formatValue={(v) => Math.round(v)} /></span>
                                        </h3>
                                        <p className="funfact-one__list__text">Satisfied Clients </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>

                    <section className="section-padding--bottom section-padding--top gray-bg background-size-cover"
                        style={{ backgroundImage: `url(${BGImg6})` }}>
                        <div className="container">
                            <div className="section-title text-center">
                                <p className="section-title__text text-center mt-5 fs-3 ">{productsSection && productsSection.main_heading}</p>
                            </div>
                            <div className="row gutter-y-30">
                                <div className='col-8 col-md-12 col-lg-10 m-auto'>
                                    <div className='row'>
                                        {mainproducts && mainproducts.length > 0 && mainproducts.map((item3, index3) => (
                                            <div key={index3} className="col-lg-4 col-md-6 col-sm-12 m-auto mt-4">
                                                <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                                                    <div className="team-card-one__image">
                                                        {item3.media &&
                                                            <img src={`${Server}` + item3.media.image} alt="" />
                                                        }
                                                        <div className="team-card-one__social">
                                                            <ul className="team-card-one__social__links">
                                                                <li>
                                                                    <FacebookShareButton url={base_url + item3.content_extra}>
                                                                        <FacebookIcon size={25} round={true} />
                                                                    </FacebookShareButton>
                                                                </li>
                                                                <li>
                                                                    <WhatsappShareButton url={base_url + item3.content_extra}>
                                                                        <WhatsappIcon size={25} round={true} />
                                                                    </WhatsappShareButton>
                                                                </li>
                                                                <li>
                                                                    <LinkedinShareButton url={base_url + item3.content_extra}>
                                                                        <LinkedinIcon size={25} round={true} />
                                                                    </LinkedinShareButton>
                                                                </li>
                                                                <li>
                                                                    <EmailShareButton url={base_url + item3.content_extra}>
                                                                        <EmailIcon size={25} round={true} />
                                                                    </EmailShareButton>
                                                                </li>
                                                            </ul>
                                                            <div className="team-card-one__social__icon">
                                                                <i className="fa fa-share-alt"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="team-card-one__content">
                                                        <h3 className="team-card-one__title mt-2"><Link to={item3.content_extra}>{item3.main_heading}</Link></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class=" cta-two">
                        <Parallax bgImage={`${Server}` + parallaxSection.image} bgImageAlt="" className="section-padding-lg--top section-padding-lg--bottom" strength={1000}>
                            <div className="container">
                                <div className="cta-two__inner">
                                    <h3 className="cta-two__title">{parallaxSection && parallaxSection.main_heading}</h3>
                                    {/* <Link to='#' className="thm-btn cta-two__btn"><span>{parallaxSection && parallaxSection.content}</span></Link> */}
                                    <Link to="#" data-bs-toggle="modal" data-bs-target="#exampleModal" className="thm-btn thm-btn--light cta-one__btn" ><span>{parallaxSection && parallaxSection.content}</span></Link>
                                </div>
                            </div>
                        </Parallax>
                    </section>

                    <section
                        className="section-padding--bottom section-padding--top testimonials-two background-repeat-no background-position-top-center"
                        style={{ backgroundImage: `url(${BGImg6})` }}>
                        <div className="container">
                            <div className="row gutter-y-60">
                                <div className="col-lg-5">
                                    <div className="testimonials-two__content">
                                        <div className='row'>
                                            <div className='col-lg-1 col-12'>
                                                <div className="section-title ">
                                                    <p className="section-title__text text-vertical">See What Our Clients Say</p>
                                                </div>
                                            </div>
                                            <div className='col-lg-11 col-12'>
                                                <div className="section-title mt-lg-5">
                                                    <h2 className="section-title__title">We Are Trusted
                                                        Worldwide People</h2>
                                                </div>
                                                <div className="testimonials-two__content__text">As an IT Company in Switzerland we dedicated our team of professionals to the highest standards of technical expertise and client care.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="testimonials-two__items">
                                        <div className="row gutter-y-30">
                                            {reviews && reviews.length > 0 &&
                                                <OwlCarousel className="slider-one__carousel owl-theme thm-owl__carousel" {...logoOptions}>
                                                    {reviews.map((item4, index4) => (
                                                        <div key={index4} className="col-lg-12">
                                                            <div className="testimonials-one-card">
                                                                <div className="testimonials-one-card__image">
                                                                    <img src="assets/images/resources/testimonial.jpg" alt="" />
                                                                </div>
                                                                <div className="testimonials-one-card__content">
                                                                    <div className="testimonials-one-card__text">
                                                                        {item4.message}
                                                                    </div>
                                                                    <h3 className="testimonials-one-card__title">{item4.name}</h3>
                                                                    <p className="testimonials-one-card__designation">
                                                                        <Star stars={item4.rating} />
                                                                    </p>
                                                                    <i className="icon-right-quote testimonials-one-card__icon"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </OwlCarousel>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="cta-one mt-5">
                        <div className="container">
                            <div className="cta-one__inner text-center wow fadeInUp" data-wow-duration="1500ms">
                                <div className="cta-one__circle"></div>
                                <div className="section-title ">
                                    <p className="section-title__text">Need Any Technology Solution</p>
                                    <h2 className="section-title__title section-title__title--light">Let's Build Something Amazing Togather</h2>
                                </div>
                                <Link to="#" data-bs-toggle="modal" data-bs-target="#exampleModal" className="thm-btn thm-btn--light cta-one__btn" ><span>Get a quote</span></Link>
                            </div>
                        </div>
                    </section>

                </>
            }

            {ytShow &&
                <div className="YouTubePopUp-Wrap">
                    <div className="YouTubePopUp-Content">
                        <span className="YouTubePopUp-Close" onClick={() => setytShow(false)}></span>
                        {aboutCompanyLeft &&
                            <iframe src={`${Server}` + aboutCompanyLeft.content_extra} title='ytvideo' allowFullScreen></iframe>
                        }
                    </div>
                </div>
            }
        </>
    )
}

export default Home